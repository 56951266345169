import SearchBoxField from "components/searchField";
import { useEffect, useState } from "react";
import { FaPen, FaWhatsapp, FaPhone } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import { IoArrowBack } from "react-icons/io5";
import { AiFillDelete } from "react-icons/ai";
import { IoMdPersonAdd } from "react-icons/io";
import { toast } from "react-toastify";
import { FieldValues, useForm } from "react-hook-form";
import InputText from "components/inputText";
import InputCheckBox from "components/inputCheckbox";
import { encrypt } from "lib/encryption";
import { ValidatedPhonenumber } from "lib/validations";
import Loader from "components/loading";
import { AiFillNotification } from "react-icons/ai";
import { TiMessages } from "react-icons/ti";

const WeddingGuests = () => {
    const [showlist, setRenderListView] = useState(false);
    const [addGuest, setRenderAddGuestView] = useState(false);
    const [weddingDay, setWeddingDay] = useState<number>(0)
    const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm<FieldValues>();
    const [guests, setGuests] = useState<any[]>([]);
    const [TotalWithPlusOne, setTotalWithPlusOne] = useState(0);
    const [Traditional, setTraditional] = useState(0);
    const [TotalGuests, setTotalGuests] = useState<any[]>([]);
    const [TotalGuestRSVPs, setTotalRSVPs] = useState(0);
    const [loading, setLoading] = useState(false);

    const getData = async (Day: number) => {
        const response = await fetch('https://weddingserver.koswell.co.za/api/v1/guest');
        const result = await response.json();
        if (!response.ok) {
            toast.error(result.message);
            return;
        }


        const results = result.result.filter((f: { WeddingDay: number; }) => f.WeddingDay === Day);

        const rawfalse = results.filter((f: { AllowedPlusOne: boolean }) => f.AllowedPlusOne === false).length;
        const rawtrue = results.filter((f: { AllowedPlusOne: boolean }) => f.AllowedPlusOne === true).length;
        const count = results.filter((f: { Confirmation: boolean, PlusOne: boolean }) => f.Confirmation === true && f.PlusOne === false).length;
        const countPlusOnes = results.filter((f: { Confirmation: boolean, PlusOne: boolean }) => f.Confirmation === true && f.PlusOne === true).length;
        setTotalWithPlusOne(rawfalse + (rawtrue * 2));
        setTotalRSVPs(count + (countPlusOnes * 2));
        setTotalRSVPs(count + (countPlusOnes * 2));
        setTotalGuests(results);

        setGuests(results);
    }

    const search = (data: any) => {

        const search = data?.target?.value;

        if (search.length >= 3) {
            const results = TotalGuests.filter((f: { Name: string, ContactNumber: string }) =>
                f.Name.toLowerCase().startsWith(search.toLowerCase()) ||
                f.ContactNumber.toLowerCase().startsWith(search.toLowerCase()));
            setGuests(results);
        }
        else {
            setGuests(TotalGuests);
        }

    }

    const switchView = async (Day: number) => {
        setWeddingDay(Day);
        await getData(Day);
        setRenderListView(true);
    }

    const shareOnWhatsapp = (name: string, phone: string, link: string) => {
        const message = `Hi ${name},

        You are invited to celebrate the special wedding day of Thabiso Nyathela & Lerato Motlhabi. Please click on the link provided to RSVP: ${link}.
        
        **Important Note:** Please save the number as "Koswell" to access the link.`;
        const phoneNumber = phone.replace(/^0/, '+27');
        const whatsapp = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsapp, '_blank');

    }

    const remindOnWhatsapp = (name: string, phone: string, link: string) => {
        const message = `Hi ${name},

        Just a reminder to RSVP before the 15th of September 2024.

        Please click on the link provided to RSVP: ${link}
        
        **Important Note:** Please save the number as "Koswell" to access the link.`;
        const phoneNumber = phone.replace(/^0/, '+27');
        const whatsapp = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsapp, '_blank');

    }

    const provideInfoOnWhatsapp = (name: string, phone: string, link: string) => {
        const message = `Hi ${name},

        Thank you for your confirmation to celebrate our special day with us.

        **More Information for the day**

        **Date** : 14th December 2024
        **Time** : 11am Matrimonial, 14pm Reception
        **Venue** : Oryx Hill Safari Lodge
        **Dress Code : Strictly Black Tie

                
        **Important Note:** Alcohol will be sold inside no alcohol will be allow to be brought inside the venue`;
        const phoneNumber = phone.replace(/^0/, '+27');
        const whatsapp = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsapp, '_blank');

    }

    const addNewGuest = async (data: any) => {
        setRenderListView(false);
        reset();
        if (data) {
            const response = await fetch(`https://weddingserver.koswell.co.za/api/v1/guest/${data?.id}`)
            const result = await response.json();
            if (!response.ok) {
                toast.error(result.message);
                return;
            }

            setValue('AllowedPlusOne', result.result.AllowedPlusOne);
            setValue('Confirmation', result.result.Confirmation);
            setValue('ContactNumber', result.result.ContactNumber);
            setValue('Declined', result.result.Declined);
            setValue('InviteUrl', result.result.InviteUrl);
            setValue('Name', result.result.Name);
            setValue('OriginalUrl', result.result.OriginalUrl);
            setValue('PlusOne', result.result.PlusOne);
            setValue('Surname', result.result.Surname);
            setValue('WeddingDay', result.result.WeddingDay);
            setValue('id', result.result.id);
        }

        setValue('WeddingDay', 1);

        setRenderAddGuestView(true);
    }



    const onSubmit = async (data: FieldValues) => {
        setLoading(true);
        const method = data?.id ? 'PUT' : 'POST';
        const url = data?.id ? `https://weddingserver.koswell.co.za/api/v1/guest/update/${data?.id}` :
            'https://weddingserver.koswell.co.za/api/v1/guest/create/'

        const dataObj = {
            Data: encrypt(JSON.stringify(data))
        }

        const response = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataObj)
        })

        const result = await response.json();
        setLoading(false);
        if (!response.ok) {
            toast.error(result.message);
            return;
        }

        toast.success('Saved');
        await getData(weddingDay);
        setRenderAddGuestView(false);
        setRenderListView(true);
    }

    const onDelete = async (data: any) => {
        const method = 'Delete'
        const url = `https://weddingserver.koswell.co.za/api/v1/guest/delete/${data?.id}`

        const response = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const result = await response.json();

        if (!response.ok) {
            toast.error(result.message);
            return;
        }

        toast.success('Deleted');
        await getData(weddingDay);
    }

    if (addGuest) {
        return (
            <div className="flex flex-col">
                {loading && <Loader />}

                <div className="fixed w-full p-1 bg-slate-500 text-white">
                    <button onClick={() => { setRenderAddGuestView(false); setRenderListView(true) }} className="z-30 w-full"><IoArrowBack size={25} className="text-white" /></button>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col gap-2 p-2 mt-10">
                        <InputText
                            fieldName="Name"
                            placeHolder="Guest Name"
                            register={register}
                            errors={errors}
                            validation={{
                                required: 'This field is required'
                            }}
                        />
                        <InputText
                            fieldName="Surname"
                            placeHolder="Guest Surname"
                            register={register}
                            errors={errors}
                            validation={{
                                required: 'This field is required'
                            }}
                        />
                        <InputText
                            fieldName="ContactNumber"
                            placeHolder="Guest Contact Number"
                            register={register}
                            errors={errors}
                            validation={{
                                required: 'This field is required',
                                validate: (value) => {
                                    if (ValidatedPhonenumber(value)) {
                                        return 'Invalid phone number format 0xxxxxxxxx'
                                    }
                                }
                            }}
                        />
                        <InputCheckBox
                            fieldName="AllowedPlusOne"
                            placeHolder="Can bring a plus one"
                            register={register}
                            errors={errors}
                        />
                        <button type="submit" className="bg-slate-500 p-2 w-full rounded-full text-white">Save</button>
                    </div>
                </form>
            </div>

        )
    }



    if (showlist) {
        return (
            <div className="relative flex flex-col gap-3 min-h-screen">

                <div className="fixed w-full bg-slate-500 text-white p-1">
                    <button onClick={() => setRenderListView(false)} className="z-30 w-full"><IoArrowBack size={25} className="text-white" /></button>
                </div>

                <div className="mt-10 flex flex-col gap-3  overflow-y-auto mb-14  p-3">
                    <SearchBoxField onChange={search} />
                    <div className="flex gap-3 justify-center align-middle">
                        <div className="flex gap-3 justify-center align-middle">
                            {weddingDay === 1 && <span className="text-center text-slate-500"><span className="border-2 border-slate-500 p-2 rounded-full bg-slate-500 text-white">{TotalWithPlusOne}</span><br />Total Invited</span>}
                            <span className="text-center text-slate-500"><span className="border-2 border-slate-500 p-2 rounded-full bg-slate-500 text-white">{TotalGuestRSVPs}</span><br />Confirmed</span>
                        </div>
                    </div>
                    {guests.map(guest =>
                        <div key={guest.id} className="rounded-md w-full border border-slate-500 py-3 flex flex-col p-2 shadow-sm shadow-slate-500">
                            <div className="w-full flex justify-between pb-2">
                                <span className={`text-sm ${guest.Confirmation ? 'text-green-400' : ''}`}>
                                    {guest.Name} {guest.Surname}
                                </span>
                                <span className="flex gap-3">
                                    <button type="button" onClick={() => addNewGuest(guest)}><FaPen size={14} className="text-slate-500" /></button>
                                    <button type="button" onClick={() => onDelete(guest)}><AiFillDelete size={14} className="text-slate-500" /></button>
                                </span>
                            </div>
                            <div className="w-full border-t border-slate-300 pt-2 flex gap-3">
                                <button><FaPhone className="text-slate-500" /></button>
                                <button onClick={() => shareOnWhatsapp(guest.Name + ' ' + guest.Surname, guest.ContactNumber, guest.InviteUrl)}><FaWhatsapp className="text-green-500" /></button>
                                {(!guest.Confirmation && !guest.Declined) && <button onClick={() => remindOnWhatsapp(guest.Name + ' ' + guest.Surname, guest.ContactNumber, guest.InviteUrl)}><AiFillNotification className="text-red-500" /></button>}
                                {guest.PlusOne ? <IoMdPersonAdd /> : ''}
                                {(guest.Confirmation && !guest.Declined) && <button onClick={() => provideInfoOnWhatsapp(guest.Name + ' ' + guest.Surname, guest.ContactNumber, guest.InviteUrl)}><TiMessages className="text-green-700" /></button>}

                            </div>
                        </div>
                    )}
                </div>

                <div className="fixed bottom-16">
                    <button onClick={() => addNewGuest(null)} className="z-30 w-full"><FaCirclePlus size={25} className="text-slate-500" /></button>
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col p-3 gap-3">
            <button onClick={() => switchView(1)} className="rounded-md w-full border border-slate-500 py-3 text-left px-2">White Wedding</button>
            <button onClick={() => switchView(2)} className="rounded-md w-full border border-slate-500 py-3 text-left px-2">Traditional Wedding</button>
        </div>
    )
}

export default WeddingGuests